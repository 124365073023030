import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"

const LocalBusinessSchemaHook = () => {
  const { site } = useStaticQuery(
    graphql`
      {
      site {
        siteMetadata {
          title
          siteUrl
          siteLogo
          businessNumber
		  businessAddress
        }
      }
    }
    `
  )

  const localBusinessSchema = `
  {
	"@context": "https://schema.org",
	"@type": "HomeAndConstructionBusiness",
	"name": "${site.siteMetadata.title}",
	"image": "${site.siteMetadata.siteUrl}${site.siteMetadata.siteLogo}",
	"@id": "https://ecotechrestoration.ca/#website",
	"url": "https://ecotechrestoration.ca/",
	"telephone": "${site.siteMetadata.businessNumber}",
	"priceRange": "$$-$$$$",
	"address": {
		"@type": "PostalAddress",
		"streetAddress": "13550 Balsam St.",
		"addressLocality": "Maple Ridge",
		"addressRegion": "BC",
		"postalCode": "V4R 0E2",
		"addressCountry": "CA"
	},
	"geo": {
		"@type": "GeoCoordinates",
		"latitude": 49.248481,
		"longitude": -122.573673
	},
	"openingHoursSpecification": {
		"@type": "OpeningHoursSpecification",
		"dayOfWeek": [
			"Monday",
			"Tuesday",
			"Wednesday",
			"Thursday",
			"Friday",
			"Saturday",
			"Sunday"
		],
		"opens": "00:01",
		"closes": "23:59"
	},
    "sameAs": [
		"https://www.instagram.com/ecotech_restoration_inc/",
		"https://www.facebook.com/ecotechrestorationinc/",
        "https://twitter.com/Ecotech_123"
	] 
}
`
  return  (
        <script type="application/ld+json" dangerouslySetInnerHTML={{__html: localBusinessSchema}} />
  )
}

export default LocalBusinessSchemaHook