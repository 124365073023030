import * as React from 'react'
import LocalBusinessSchemaHook from '../components/hooks/schema/localBusinessSchema'
import { StaticImage } from 'gatsby-plugin-image'
import { css } from '@emotion/react'

import Layout from '../components/layout'
import Seo from '../components/seo'

// images

import SiteWidthWrapper from '../components/wrappers/siteWidthWrapper'

const ContactUsPage = () => {
  return (
    <Layout>
      <Seo
        title="Contact Ecotech Restoration"
        description="Contact Ecotech Restoration today for your next restoration or repair project. Emergency repair services available."
        canonical="https://ecotechrestoration.ca/contact/"
      />
      <LocalBusinessSchemaHook />
      {/* Heros */}
      <div className="heroMobile">
        <StaticImage
          src="../images/heros/map.jpg"
          // maxHeight={600}
          quality={95}
          formats={['AUTO', 'WEBP']}
          alt="Contact Ecotech Restorations"
        />
      </div>
      <SiteWidthWrapper>
        <div
          css={css`
            display: grid;
            margin: 0 auto;
            max-width: var(--max-width);
            h2,
            p {
              text-align: center;
            }
          `}>
          <h1
            css={css`
              margin-top: 0;
              padding-top: 0;
              text-align: center;
            `}>
            Contact Us
          </h1>
          <h2>
            GET IN TOUCH{' '}
            <span
              css={css`
                color: var(--green);
              `}>
              WITH US
            </span>
          </h2>
          <p>
            Contact us today and get fast 24 hour emergency response restoration
          </p>
        </div>
        <div
          css={css`
            @media (min-width: 768px) {
              display: grid;
              grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
              margin: 0 auto;
              max-width: var(--max-width);

              .vancouver,
              .MapleRidge,
              .PoCo,
              .Burnaby {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-content: center;
              }
            }
          `}>
          <div className="vancouver">
            <p className="bold">Vancouver Office</p>
            <p>
              #1500 - 701 West Georgia St.
              <br />
              Vancouver, B.C.
              <br />
              V7Y 1G5
            </p>
          </div>
          <div className="MapleRidge">
            <p className="bold">Maple Ridge Office</p>
            <p>
              13550 Balsam St.
              <br />
              Maple Ridge, B.C.
              <br />
              V4R 0E2
            </p>
          </div>
          <div className="PoCo">
            <p className="bold">Port Coquitlam Office</p>
            <p>
              1B 1642 Langen Ave
              <br />
              Port Coquitlam, B.C.
              <br />
              V3C 1K5
            </p>
          </div>
          <div className="Burnaby">
            <p className="bold">Burnaby Office</p>
            <p>
              7656 Winston St.
              <br />
              Burnaby, B.C.
              <br />
              V5a 2H4
            </p>
          </div>
        </div>
      </SiteWidthWrapper>
    </Layout>
  )
}

export default ContactUsPage
